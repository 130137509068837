* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
}

html {
  background: linear-gradient(#3f3045, #ffc0cb);
  scroll-behavior: smooth;
}

h2 {
  font-size: 4rem;
  padding-bottom: 3rem ;
  padding-top: 6rem ;
  font-family: "Berkshire Swash", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #ffffff;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 30%);
}

h3 {
  color: #ffffff;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 30%);
}



