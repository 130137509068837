

#menu-sup {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: fixed;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: space-between;
  transition: height 0.4s ease-in-out;
  background-color: #3f3045;
  z-index: 990;
  height: 130px;
}

#links a p{
  color: rgb(247, 247, 247) !important
}


#links {
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

#center{
  align-items: center;
  display: flex;
  flex-direction: column;
}

#logo-sup {
  display: block;
  transition: transform 0.9s ease-in-out, opacity 2s ease-in-out;
  transform: translateY(0%);
  opacity: 1;
  width: 90px;
  height: 90px;
}

#span{
  display: block;
  margin: 0 auto;
  height: 0;
  transition: transform 0.6s ease-in-out, opacity 2s ease-in-out;
  transform: translateY(200%);
  opacity: 0;
  color: #fff;
  font-family: Berkshire Swash,sans-serif;
  width: 100%;
  height: 0;
  text-shadow: 0px 2px 5px rgb(0 0 0 / 30%);
  font-size: 3em;
}


#left {
  visibility: hidden;
}

.nav-btn {
  display: none;
}

header nav a {
  /* margin-right: 15px; */
  /* flex-basis: 33.33%; */
  color: white;
  font-size: 13px;
}

nav a:hover {
  font-weight: bold;
}

header .nav-btn {
  color: white;
  background-color: #3f3045;
}

h1 {
  font-family: 'Berkshire Swash', sans-serif;
}



@media only screen and (max-width: 1024px) {

  /* #span{
    
  } */

  #center{
    display: grid;
    margin-left: 5%;
  }
  
  header .nav-btn {
    display: flex;
    left: 15;
    visibility: visible;
    opacity: 1;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1.7rem;
    min-height: 15%;
  }

  #links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 16%;
    height: 35%;
    width: 100%;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.509);
  }



  header nav a {
    display: flex; /* convierte la etiqueta a en un contenedor flexible */
    justify-content: center; /* centra horizontalmente el contenido */
    align-items: center; /* centra verticalmente el contenido */
    height: 100%; /* establece la altura del contenedor en 100% */
    width: 100%;
    border-top: 1px dotted rgba(0, 0, 0, 0.275);
    
  }


  #left {
    display: none;
    margin-left: 5%;
    flex-direction: column;
    visibility: visible;
  }

  /* #center {
  } */

  #rigth {
    display: flex;
    margin-right: 5%;
    flex-direction: column;
  }


  #menu-sup {
    display: flex;
    flex-direction: row;
    min-height: 90px;
    
  }

  header nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #302535;
    transition: 1s;
    transform: translateY(-100vh);
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 4%;
    right: 8%;
    color: white;
    margin: 0;
    font-size: 1rem;
  }
}


/* prueba botoncio */


  .nav__toggler {
    margin: auto;
    display: none;
    }
    .nav__toggler div {
    display: block;
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: rgb(204, 204, 204);
    transition: 0.3s ease-in;
    }


    @media only screen and (max-width: 1024px) {

      .nav__toggler {
        display: block;
        cursor: pointer;
        }


        .toggle .line1 {
          transform: rotate(-45deg) translate(-4px, 5px);
          }
          .toggle .line2 {
          opacity: 0;
          }
          .toggle .line3 {
          transform: rotate(45deg) translate(-8px, -9px);
          }
        

      }