#tyc{
    background-color: rgba(255, 255, 255, 0);
    font-family: 'Cinzel Decorative';
    color: #FFF;
    border: none;
    cursor: pointer;
    margin: 10px;
}

#tyc:hover {
    font-weight: bold;
}

#tyctarot {
    font-family: 'Cinzel Decorative';
    font-size: 0.5rem;
    width: 90%;
    margin: auto;
    color: #FFF;
}


.modal-body p{
    color:#FFF;
    font-size: 0.7em;
    }


.modal-title{
color:#FFF;
}



#close-modal {
    background-color: #3f3045;
    color: #FFF;
    border: 2px solid white;

    font-family: Cinzel Decorative;
    padding: 0.6em;
    border-radius: 50px;
    cursor: pointer;

}

#close-modal:hover {
    background-color: #FFF;
    color: #3f3045;
    border: 2px solid transparent;
}



footer {
    background-color: #3f3045;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
}

.modal {
    position: fixed;
    top: 5%;
    left: 10%;
    z-index: 1060;
    display: block !important;
    width: 80%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-dialog {
    overflow-y: initial !important;
    position: relative;
    width: auto;
    /* margin: .5rem; */
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50px);
    overflow-y: initial !important;

}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
        
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}


.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #3f3045;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 1rem;
    outline: 0;
    font-family: Cinzel Decorative;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}


.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    flex-direction: column;
}

/* .modal-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto
} */

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;
}

.modal-footer {
    display: flex;
    flex-shrink: 0;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    flex-direction: column;
    align-items: center;
}

.modal-footer>* {
    margin: .25rem
}

/* @media (min-width:576px) {
    .modal-dialog {
        max-width: 70%;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
} */
/* 
@media (min-width:992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
} */

/* @media (min-width:1200px) {
    .modal-xl {
        max-width: 1140px
    }
} */

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
    max-height: 300px;
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

/* @media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
} */
/* 
@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
} */

/* @media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
} */