@import url('https://fonts.cdnfonts.com/css/berkshire-swash');


.header {
height: 100vh;
background: url('../../images/principal2.png') no-repeat 48% fixed ;
background-size: auto;
background-size: cover;
min-height: 750px;
}

.header .overlay {
    height: 100%;
    background: rgba(33,37,41,.85);
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

.header #titulo{
    color: #fff ;
    font-family: 'Berkshire Swash', sans-serif;
    font-size: 3.5rem;
}

.header #logo-inicio{
width: 180px;
}




#container-main{
  height: 55%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
}


  header span{
box-sizing: border-box;
display: inline-block;
overflow: hidden;
width: initial;
height: initial;
background: none;
opacity: 1;
border: 0;
margin: 0;
padding: 0;
position: relative;
max-width: 100%;
  }


  #frase{
    margin: 20px;
    color: rgba(255, 255, 255, 0.9) ;
    font-size: 1.3rem;
    font-family: 'Cinzel Decorative';    
    font-weight: 700;  

  }

  header a{
    font-family: 'Cinzel Decorative';
    font-size: 10px;
    /* color: rgba(255, 255, 255, 0.455) !important; */
    text-decoration:none;
  }



  ol, ul {
    padding-left: 0rem;
  }

.btn_navbar{
  background-color: #4dc247;
  color: white;
  opacity: 100;
  font-size: 150%;
  display: inline-flex;
  justify-content: space-around;
  border: 1px solid transparent;
  padding: 0.2rem;
  line-height: 2;
  border-radius: 2rem;
  align-items: center; 
}

.btn_navbar:hover{
  color: #4dc247;
  background-color:white;
  }

#whatsapp-icon{
  font-size: 25px;
  margin: 0 5px 0 10px;
  font-weight: bold;
}

#contact{
  margin: 2px 10px 0 0;
  color: white;
  font-weight: bold;
  }

  #contact:hover{
  color: #4dc247;
  font-weight: bold;
  }

    .btn-wsp{
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 4%;
      right: 4%;
      background: #4dc247;
      color: #FFF;
      border-radius: 50px;
      font-size: 40px;
      box-shadow: 0px 1px 10px rgb(0 0 0 / 30%);
      z-index: 100;
      transition: all 300ms ease;
      display: flex;
      justify-content: center;
      align-items: center; 
    
    }
    
    .btn-wsp:hover{
      background: #FFF;
      color:  #4dc247;
    }
    
    .btn-wsp svg{
      margin-bottom: 2px;
    }