/* Popup.css */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    /* padding: ; */
    border-radius: 10px;
    position: relative;
    width: 70%;
    /* height: 10%; */
  }
  
  .popup-image {
    height: 450px;
    border-radius: 10px;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .popup-close {
    position: absolute;
    /* top: 10px; */
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: white;
    
    
  }
  
  @media (max-width: 768px) {
    /* Estilos para dispositivos móviles */
    .popup-image {
      border-radius: 50%;
        width: 300px;
        height: 300px;
    }
  }
  